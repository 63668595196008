.uncove-login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 400px;
    margin: 0 auto;
}

.uncove-login-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 30px;
    text-align: center;
}

.error-message {
    color: #ff5a36;
    margin: 10px 0;
    text-align: center;
    background-color: #FEEBE6;
    padding: 12px 20px;
    border-radius: 8px;
    font-size: 0.95rem;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
    from {
        transform: translateY(-10px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.button-group {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    width: 100%;
    justify-content: center;
}

.button-group .button-outline {
    background-color: transparent;
    color: #ff5a36;
    border: 1px solid #ff5a36;
    border-radius: 100px;
    padding: 12px 35px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    min-width: 140px;
}

.button-group .button-outline:hover {
    background-color: #ff5a36;
    color: white;
}

.button-group .new-bot-button {
    background-color: #ff5a36;
    color: white;
    border: none;
    border-radius: 100px;
    padding: 12px 35px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    min-width: 140px;
}

.button-group .new-bot-button:hover {
    background-color: #DC2A04;
}

.button-group .new-bot-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
} 