.profile-container {
    text-align: left;
    padding: 40px;
    border-radius: 10px;
    max-width: 800px;
    margin: 20px auto;
    font-family: 'Arial', sans-serif;
}

.profile-title {
    font-size: 2rem;
    color: #333;
    margin: 40px 0 20px 0;
    font-weight: bold;
}

.profile-subtitle {
    font-size: 1.8rem;
    color: #333;
    margin: 40px 0 20px 0;
    font-weight: bold;
}


.new-bot-button {
    background-color: #ff5a36;
    color: white;
    border: none;
    border-radius: 25px;
    padding: 12px 30px;
    cursor: pointer;
    font-size: 1rem;
    margin: 20px 0;
    transition: background-color 0.3s;
}

.new-bot-button:hover {
    background-color: #DC2A04;
}

.subscription {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    border: 1px #FEEBE6 solid;
    /* background: #FEEBE6; */
    border-radius: 10px;
    padding: 15px;
    margin: 10px 0;
}

.subscription-name {
    font-weight: bold;
    font-size: 1.5rem;
    color: #ff5a36;
}

.subscription-details,
.renewal-info {
    display: block;
    color: #999999;
    margin-top: 5px;
}

.subscription-details {
    font-weight: bold;
}

.button-outline {
    background-color: transparent;
    color: #ff5a36;
    border: 1px solid #ff5a36;
    border-radius: 25px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s, color 0.3s;
}

.button-outline:hover {
    background-color: #ff5a36;
    color: white;
}

.logout-button {
    color: #ff5a36;
    background: none;
    border: none;
    font-size: 1rem;
    margin-top: 30px;
    cursor: pointer;
}

.new-bot-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.new-bot-title {
    font-weight: bold;
    font-size: 22px;
}

.loading-container {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 10%);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.loading-box {
    z-index: 11;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    background: white;
    border-radius: 20px;
    border: 1px #aaa solid;
}

.subscription-buttons {
    display: flex;
    gap: 10px;
}

.details-button {
    background-color: #ff5a36;
    color: white;
}

.details-button:hover {
    background-color: #DC2A04;
    color: white;
}