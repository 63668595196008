.maintenance-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
}

.maintenance-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

.maintenance-text {
    color: #666;
    line-height: 1.6;
    margin-bottom: 30px;
}

.maintenance-code {
    width: 100%;
    max-width: 200px;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 50px;
    font-size: 14px;
    margin-bottom: 20px;
    text-align: center;
    color: #666;
    background-color: #f9f9f9;
}

.maintenance-code::placeholder {
    color: #999;
}

.maintenance-code:focus {
    outline: none;
    border-color: #ff5a36;
}

.maintenance-container .button-outline {
    background-color: transparent;
    color: #ff5a36;
    border: 1px solid #ff5a36;
    border-radius: 100px;
    padding: 12px 35px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    min-width: 140px;
}

.maintenance-container .button-outline:hover {
    background-color: #ff5a36;
    color: white;
} 